import React from "react";
import HaloSectionView from "./HaloSection.view";
import { data, paidCoursesIdAndLabelsData } from "./HaloSection.model";
import { useRouter } from "next/router";
import { convertSlugToString } from "@/outscal-commons-frontend/utils/stringOperations";

const HaloSection = ({
  paidCoursesIdAndLabels,
  heading,
  subHeading,
  section_id,
  bgImage,
}) => {
  const router = useRouter();
  return (
    <HaloSectionView
      id={section_id}
      data={data}
      heading={heading}
      subHeading={subHeading}
      bgImage={bgImage}
      hasBgImage={bgImage?.length > 0 ? true : false}
      links={[
        { id: 1, label: "Home ", to: "/" },
        {
          id: 2,
          label: `${convertSlugToString(
            router.asPath.match(/\/([^\/\?]+)(\?|$)/)?.[1] || ""
          )}`,
          to: "",
        },
      ]}
      paidCoursesIdAndLabels={paidCoursesIdAndLabelsData}
    />
  );
};

export default HaloSection;
