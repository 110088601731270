import { Theme } from "@/outscal-commons-frontend/Styled/Theme";
import { Div, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import Link from "next/link";
import React from "react";
import styled from "styled-components";

const Root = styled(Div)`
  display: flex;
  align-items: center;
  gap: 5px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.colors.blue};
  @media screen and (max-width: 768px) {
    font-size: ${(p) => p.theme.fontSize.sm};
  }
  &:hover {
    text-decoration: underline;
  }
`;

const Text = styled.span``;

const Navigations = ({ links }) => {
  return (
    <Root>
      {links.map((linkDetails, index) => (
        <RowDiv key={linkDetails.id} alignItems="center">
          {linkDetails.to ? (
            <StyledLink href={linkDetails.to}>{linkDetails.label}</StyledLink>
          ) : (
            <Text color={Theme.colors.blue}>{linkDetails.label}</Text>
          )}
          {index < links.length - 1 ? (
            <span dangerouslySetInnerHTML={{ __html: "&nbsp;>" }} />
          ) : (
            ""
          )}
        </RowDiv>
      ))}
    </Root>
  );
};

export default Navigations;
