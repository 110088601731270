import Image from "next/image";
import { styled } from "styled-components";
import Constant from "@/staticData/constant";
import { RowDiv, ColDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";

export const MainContainer = styled.section`
  width: 100%;
  /* display: ${(p) => (p.showLoggedInOrMobile ? "block" : "none")}; */

  margin: 0;
  padding: 0;
  @media (max-width: 768px) {
    margin-top: 70px;
  }
  @media (min-width: 600px) {
    display: block;
  }
`;

export const Container = styled(RowDiv)`
  display: flex;
  position: relative;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media screen and (min-width: 768px) {
    height: calc(100vh - ${Constant.NAVBAR_HEIGHT});
  }
`;

export const DetailsSection = styled(ColDiv)`
  width: -webkit-fill-available;
  color: ${(p) => p.theme.colors[p.hasBgImage ? "white" : "black"]};
  border-radius: 12px;
  flex: 0.8;
  @media screen and (max-width: 768px) {
    flex: 1;
  }
`;

export const Heading = styled.h1`
  font-size: 48px;
  @media screen and (max-width: 768px) {
    font-size: ${(p) => p.theme.fontSize.xxxxl};
  }
`;

export const BgImage = styled(Image)`
  max-height: 100vh;
  filter: brightness(40%);
`;
