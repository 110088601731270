export const data = {
  heading: "Game Development Courses",
  subHeading:
    "Upskill your career with our game development course that covers the main aspect of development i.e. programming. Get an idea of how to work with game design and animation teams to bring your games to life. Learn gaming courses with our real word experts and get a job as a game developer in a top gaming studio like Ubisoft, EA, Rockstar, etc. ",
    bgImage:
    "https://outscal-content.s3.ap-south-1.amazonaws.com/fullSize/full-stack-game-development.png",
};

export const paidCoursesIdAndLabelsData = [
  {
    id: "full-stack-game-development",
    label: "Full-Stack Game Development ",
  },
  {
    id: "game-development-fast-track",
    label: "Game Development Fast Track",
  },
  {
    id: "game-development-advance",
    label: "Game Development Advance",
  },
];
