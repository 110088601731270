import React from "react";
import UseComponentVisibility from "@/hooks/useComponentVisibility";
import HaloSection from "@/mvcComponents/PageComponents/CoursesPage/HaloSection/HaloSection.controller";

const CourseLeads_Halo = ({
  heading,
  subHeading,
  section_id,
  bgImage,
  showOn,
  showLoggedInOrMobile = false,
}) => {
  return (
    <UseComponentVisibility conditions={showOn}>
      <section data-outscal-section className="lead-form">
        <HaloSection
          heading={heading}
          subHeading={subHeading}
          section_id={section_id}
          bgImage={bgImage?.url}
          showLoggedInOrMobile={showLoggedInOrMobile}
        />
      </section>
    </UseComponentVisibility>
  );
};

export default CourseLeads_Halo;
